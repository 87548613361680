// IMPORTS
// =====================
@import "./colors.scss";
@import "./fonts.scss";
@import "./spinkit.css";
@import "./screens.scss";
@import "./modals.scss";

// MIXINS
// =====================

// PLUGINS
// =====================
.ace_hidden-cursors {
  opacity: 0;
}

// GLOBALS
// =====================
body {
  margin: 0;
  padding: 0;
}

a, input, textarea {
  all: unset;
  display: block;
}

* {
  box-sizing: border-box;
}

input {
  box-sizing: border-box;
  padding: 5px;
  border: 1px solid $black;
}

textarea {
  box-sizing: border-box;
  border: 1px solid $black;
}

h1, h2, h3, h4, h5, h6, p, hr, pre, a, input, span, textarea, label, strong {
  font-family: 'Ubuntu';
  margin: 0;
}

p {
  font-weight: 400;
  font-size: 16px;
}

a, button {
  cursor: pointer;
}

hr {
  width: 100%;
}

.flex-spacer {
  flex: 1;
}

// LAYOUTS
// =====================
$primary-header-height: 64px;
$primary-header-menu-height: 40px;

$secondary-header-height: 48px;
$header-content-margin-left: 120px;
$header-content-margin-right: 240px;
$footer-margin-top: 0px;
$footer-height: 280px;
$value-prop-height: 360px;

.Container {
  min-height: 100vh;
  background-color: $white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.App {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.content-container {
  width: 840px;
}

.content-container-wide {
  width: 100%;
  padding-left: 72px;
  padding-right: 72px;
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  .desktop {
    display: none !important;
  }
}

@media screen and (min-width: 480px) {
  .mobile {
    display: none !important;
  }
}

// COMPONENTS
// =====================
.Header.primary {
  height: $primary-header-height;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid $crowdin-border-gray;

  @media screen and (min-width: 0px) and (max-width: 480px) {
    min-width: 100vw;
    width: 100vw;
    max-width: 100vw;
  }

  @media screen and (min-width: 480px) {
    width: 100%;
  }

  .branding {
    cursor: pointer;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: $white;
    padding-left: 24px;

    img {
      height: 56%;
      margin-right: 8px;
    }
  }

  #blendin-locale-select {
    margin-left: 32px;
    margin-right: 24px;
  }

  .nav {
    margin-left: 32px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    a { color: $white; }

    .nav-item {
      @media screen and (min-width: 0px) and (max-width: 480px) {
        display: none;
      }

      @media screen and (min-width: 480px) {
        color: $ngrok-primary-text;
        cursor: pointer;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
        transition: background-color 200ms, color 200ms;
        &:not(:last-child) {
          margin-right: 8px;
        }
        &:hover {
          background-color: $white;
          color: $consulting-primary;
        }
        &.selected {
          text-decoration: underline;
          text-underline-offset: 6px;
          color: $consulting-primary;
        }
      }

    }

  }

  .current-user-container {
    @media screen and (min-width: 0px) and (max-width: 480px) {
      display: none;
    }

    @media screen and (min-width: 480px) {
      position: relative;
      margin-right: 40px;
      background-color: $white;
      height: 40px;
      border-radius: 4px;
      display: flex;
      justify-content: flex-start;
      align-items: stretch;

      .current-user {
        height: 100%;
        border-radius: 4px;
        padding-left: 12px;
        padding-right: 12px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:hover {
          background-color: $crowdin-border-gray;
        }
      }
    }

  }

  .current-language-container, .current-user-container, .hamburger-menu-container {
    .dropdown-menu {
      cursor: pointer;
      position: absolute;
      top: 48px;
      &.language-dropdown-menu { left: 0; }
      &.user-dropdown-menu { right: 0; }
      &.hamburger-dropdown-menu {
        top: 56px;
        right: 20px;
      }
      z-index: 3;
      width: 192px;
      max-height: 500px;
      overflow: scroll;
      background-color: white;
      border-radius: 6.5px;
      box-shadow: 0 12px 28px 0 #0003,0 2px 4px 0 #0000001a;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      padding-top: 8px;
      padding-bottom: 8px;

      .dropdown-item {
        cursor: pointer;
        min-height: 28px;
        &:hover {
          margin-left: 5px;
          margin-right: 5px;
          background-color: $notion-gray-highlight;
          border-radius: 4px;

          .dropdown-item-text {
            margin-left: 19px;
          }
        }
        display: flex;
        flex-direction: column;
        justify-content: center;
        .dropdown-item-text {
          margin-left: 24px;
        }
      }
    }
  }

  .auth-actions {
    @media screen and (min-width: 0px) and (max-width: 480px) {
      display: none;
    }

    @media screen and (min-width: 480px) {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 100%;
      padding-left: 20px;
      padding-right: 40px;
      a { color: $white; }

      .signup {
        margin-left: 12px;
      }

      .auth-item {
        color: $ngrok-primary-text;
        cursor: pointer;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
        transition: background-color 200ms, color 200ms;
        &:hover {
          background-color: $white;
          color: $consulting-primary;
        }
        &.selected {
          text-decoration: underline;
          text-underline-offset: 6px;
          color: $consulting-primary;
        }
      }
    }
  }

  .hamburger-menu-container {
    @media screen and (min-width: 0px) and (max-width: 480px) {
      cursor: pointer;
      margin-right: 24px;
      .hamburger-menu {
        height: 32px;
        width: 32px;
      }
    }

    @media screen and (min-width: 480px) {
      display: none;
    }
  }
}

.Footer {
  @media screen and (min-width: 0px) and (max-width: 480px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 24px;

    .branding {
      margin-top: 24px;

      .socials-container {
        margin-top: 12px;

        .social-logo {
          cursor: pointer;
          height: 18px;
          width: 18px;
        }
      }

    }

    .section-containers {
      margin-top: 24px;
      display: flex;
      flex-direction: row;
      width: 75%;
      margin-left: 5%;

      .section-container {
        display: flex;
        flex-direction: column;

        .section-item {
          margin-top: 12px;
        }
      }
    }

  }

  @media screen and (min-width: 480px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: $footer-height;

    .branding {
      margin-right: 100px;

      .socials-container {
        margin-top: 36px;

        .social-logo {
          cursor: pointer;
          height: 18px;
          width: 18px;
        }
      }
    }

    .section-container {
      height: 50%;
      display: flex;
      flex-direction: column;
      margin-left: 48px;

      .section-title {
        margin-bottom: 12px;
      }

      .section-item {
        margin-bottom: 6px;
      }
    }

  }

  margin-top: $footer-margin-top;
  width: 100%;
  border-top: 1px solid $crowdin-border-gray;
  display: flex;

  .made-with-love {
    margin-left: 24px;
  }

  .branding {
    display: flex;
    flex-direction: column;

    .blendin {
      font-size: 32px;
      font-weight: 700;
    }

    .socials-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .social-logo {
        cursor: pointer;
        height: 18px;
        width: 18px;
      }
    }

  }

}

.Flash-Message {
  height: 80px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  &.success { background-color: lighten($crowdin-light-green, 35%); }
  &.error { background-color: lighten(red, 42%); }
  &.info { background-color: lighten(#FFBF00, 42%); }

  p.message-text {
    font-size: 18px;
    font-weight: 600;
  }

  .close {
    cursor: pointer;
    position: absolute;
    top: 8px;
    right: 18px;
    font-weight: 600;
  }
}

.Sub-Navigation {
  display: flex;
  flex-direction: column;
  .nav-items {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $crowdin-gray-010;
    .nav-item {
      cursor: pointer;
      height: 56px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &:not(:first-child) {
        margin-left: 24px;
      }
      p {
        color: $crowdin-gray-054;
        font-size: 16px;
        font-weight: 500;
        &:hover {
          color: $crowdin-green;
        }
      }
      &.active {
        padding-top: 4px;
        border-bottom: 4px solid $crowdin-light-green;
        p {
          color: $crowdin-gray;
        }
      }
    }
  }
}

.Toggle-Switch {
  display: flex;
  flex-direction: row;
  border: 1px solid $black;
  border-radius: 8px;

  .toggle-option {
    padding: 12px;
    cursor: pointer;
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100px;

    &.selected {
      background-color: fade-out($white, 0.5);
    }

    &:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}

.action-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 4px;
  transition: background-color 250ms, color 250ms;
  p {
    font-weight: 600;
    font-size: 14px;
    text-align: center;
  }
  &.primary {
    background-color: $crowdin-green;
    p { color: $white; }
    &:hover {
      background-color: $crowdin-green-dark;
      p { color: $white; }
    }
  }
  &.secondary {
    background-color: $white;
    p { color: $consulting-primary; }
    // &:hover {
    //   background-color: $crowdin-green-dark;
    //   p { color: $white; }
    // }
  }
  &.tertiary {
    background-color: $white;

    &:hover {
      background-color: $crowdin-light-gray;
    }
    border: 1px solid $crowdin-gray-012;
    border-radius: 8px;
  }
  &.rounded { border-radius: 30px; }
}

.labeled-input {
  &:not(:first-child) {
    margin-top: 16px;
  }
  p.input-label {
    margin-bottom: 8px;
  }
  input[type='text'], input[type='password'] {
    width: 100%;
    height: 48px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid $charcoal-ultra-light;
    border-radius: 4px;
  }

  textarea {
    width: 100%;
    height: 480px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid $charcoal-ultra-light;
    border-radius: 4px;
  }
}

.data-table {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .table-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    border-top: 1px solid $crowdin-gray-012;
    border-bottom: 1px solid $crowdin-gray-012;
    height: 36px;
    p {
      text-align: center;
      font-size: 12px;
      color: $crowdin-gray-054;
    }
  }

  .table-body {
    .table-row {
      height: 36px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 12px;
      padding-right: 12px;
      cursor: pointer;
      &:nth-child(odd) {
        background-color: $crowdin-gray-002;
      }
      &:hover {
        background-color: darken($crowdin-light-gray, 3%);
      }
      p {
        text-align: center;
        font-size: 13px;
      }
      div.action-buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
      }
    }
  }

  .table-controls {
    margin-top: 32px;
    margin-bottom: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .table-item-info {
      width: 132px;
      margin-left: 16px;
      margin-right: 16px;
      font-size: 14px;
      font-weight: 600;
    }

    .controls {
      display: flex;
      flex-direction: row;
    }

    .control {
      cursor: pointer;
      &:hover { background-color: $crowdin-light-gray; }
      border: 1px solid $crowdin-gray-012;
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      &.control-back {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      &.control-forward {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }

  }

  &.columns-2 { p.string, div.action-buttons { width: calc(100% / 2); } }
  &.columns-3 { p.string, div.action-buttons { width: calc(100% / 3); } }
  &.columns-4 { p.string, div.action-buttons { width: calc(100% / 4); } }
  &.columns-5 { p.string, div.action-buttons { width: calc(100% / 5); } }
  &.columns-6 { p.string, div.action-buttons { width: calc(100% / 6); } }
  &.columns-7 { p.string, div.action-buttons { width: calc(100% / 7); } }
  &.columns-8 { p.string, div.action-buttons { width: calc(100% / 8); } }
}

.circled-label {
  border: 1px solid $crowdin-green;
  border-radius: 24px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - $primary-header-height - $footer-margin-top - $footer-height);
}

.locale-name-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .locale-iso {
    margin-left: 12px;
  }
}

.target-locales-select {
  height: 480px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  border-radius: 8px;
  border: 1px solid $crowdin-border-gray;
  margin-bottom: 48px;

  .select-locales {
    width: 50%;
    max-height: 100%;
    overflow-y: scroll;
    .target-locale-select {
      &:hover { background-color: $crowdin-ultralight-gray-005; }
      cursor: pointer;
      display: flex;
      flex-direction: row;
      height: 32px;
      align-items: center;
      .checkbox-wrapper {
        top: 0px;
        margin-left: 8px;
        margin-right: 0px;
      }
      .locale-flag {
        width: 14px;
        margin-left: 10px;
      }
      p {
        margin-left: 8px;
      }
    }
  }

  .selected-locales {
    width: 50%;
    background-color: $crowdin-light-gray;

    .selected-locale {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .locale-flag {
        width: 14px;
        margin-left: 10px;
      }
      .selected-locale-text {
        margin-left: 10px;
      }

    }
  }

}

// SCREENS
// =====================
.documentation-screen {
  .documentation-title {
    margin-top: 42px;
  }
}

.project-create-screen, .project-translation-job-create-screen {
  display: flex;
  justify-content: center;

  .page-title {
    font-size: 32px;
    margin-top: 42px;
  }

  .form-section {
    margin-top: 32px;
    margin-bottom: 80px;
  }

  .privacy-mode {
    margin-top: 32px;
    display: flex;
    flex-direction: column;

    .privacy-mode-option {
      &:not(:first-child) {
        margin-top: 10px;
      }
    }
  }

  .source-locale {
    margin-top: 32px;
  }

  .source-locale-select {
    cursor: pointer;
  }

  .target-locales {
    margin-top: 32px;
  }

  .target-locales-select {
    height: 480px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    border-radius: 8px;
    border: 1px solid $crowdin-border-gray;
    margin-bottom: 48px;

    .select-locales {
      width: 50%;
      max-height: 100%;
      overflow-y: scroll;
      .target-locale-select {
        &:hover { background-color: $crowdin-ultralight-gray-005; }
        cursor: pointer;
        display: flex;
        flex-direction: row;
        height: 32px;
        align-items: center;
        .checkbox-wrapper {
          top: 0px;
          margin-left: 8px;
          margin-right: 0px;
        }
        p {
          margin-left: 8px;
        }
      }
    }

    .selected-locales {
      width: 50%;
      background-color: $crowdin-light-gray;
    }

  }

  .action-buttons {
    display: flex;
    flex-direction: row;
  }
}

.user-settings-screen {
  display: flex;
  justify-content: center;

  .user-settings-title {
    margin-top: 42px;
  }

  .api-tokens-title {
    margin-top: 42px;
  }

  .action-button.new-token {
    margin-top: 24px;
    width: 136px;
  }

  .action-button.purchase-subscription {
    margin-top: 24px;
    width: 206px;
  }

  .action-button.update-payment-method {
    margin-top: 24px;
    width: 112px;
  }

  .action-button.add-payment-method {
    margin-top: 24px;
    width: 224px;
  }

  .api-tokens {
    margin-top: 24px;
  }

  .oauth-integrations-title {
    margin-top: 42px;
  }

  .subscription-title {
    margin-top: 42px;
  }

  .payment-method-title {
    margin-top: 42px;
  }

  .active-card {
    display: flex;
    flex-direction: row;

    .card-last4 {
      margin-left: 12px;
    }
  }

  .payments-title {
    margin-top: 42px;
  }

  .payments-table {
    margin-top: 24px;
  }
}

.project-locale-show-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .project-locale-name-container {
    margin-top: 42px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .project-locale-iso {
      margin-left: 12px;
    }
  }

  .translations-header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 42px;
  }

  .translations-table {
    margin-top: 18px;
  }

}

.translator-dashboard-screen {
  display: flex;
  justify-content: center;

  h2 { margin-top: 42px; }
}

.translator-confirm-translations-screen {
  .locale-name-container {
    margin-top: 42px;
  }
}
