// COLORS
// =====================

// Blendin
$blendin-green: rgb(67, 160, 71);

// LANDING PAGE
$blendin-secondary-gray: rgba(244, 247, 250, 1);

// LEGACY
$black: #000;
$white: #FFF;
$charcoal-ultra-light: #C2C2C2;

$ngrok-primary-text: rgb(51, 51, 51);
$ngrok-secondary-text: rgb(102, 102, 102);

// blue
$consulting-alternate: rgb(55, 100, 235);
$consulting-alternate-dark: rgb(13, 56, 133);
$consulting-alternate-light: rgb(236, 244, 254);
// green
$consulting-primary: rgb(47, 143, 62);
$consulting-primary-dark: darken($consulting-primary, 15%);
$consulting-primary-light: rgb(236, 254, 244);

$crowdin-dark-blue: rgb(47, 51, 63);
$crowdin-border-gray: rgb(207, 216, 220);
$crowdin-light-gray: rgb(245, 247, 248);
$crowdin-ultralight-gray: rgb(236, 239, 241);
$crowdin-ultralight-gray-005: rgba(236, 239, 241, 0.5);
$crowdin-green: rgb(67, 160, 71);
$crowdin-light-green: rgb(109, 194, 113);
$crowdin-light-blue: rgb(156, 199, 255);
$crowdin-green-dark: darken($crowdin-green, 15%);
$crowdin-gray: rgb(38, 50, 56);
$crowdin-gray-002: rgba(38, 50, 56, 0.02);
$crowdin-gray-004: rgba(38, 50, 56, 0.04);
$crowdin-gray-010: rgba(38, 50, 56, 0.10);
$crowdin-gray-012: rgba(38, 50, 56, 0.12);
$crowdin-gray-054: rgba(38, 50, 56, 0.54);
$crowdin-gray-087: rgba(38, 50, 56, 0.87);

$consulting-hero-title: rgb(6, 22, 58);

// From Turnkey
$turnkey-black: #111214;
$turnkey-dark-gray: #2B2F33;

// NOTION
$notion-gray-highlight: rgba(55, 53, 47, 0.06);
