// SHARED
// =====================
.Modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-content {
    width: 500px;
    background-color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .modal-title-container {
    padding-left: 18px;
    padding-right: 18px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid $crowdin-gray-012;

    .modal-title {
      padding-top: 16px;
      padding-bottom: 16px;
    }

    .modal-close {
      cursor: pointer;
      font-size: 20px;
      font-weight: 600;
    }
  }

  .modal-body {
    padding-left: 18px;
    padding-right: 18px;
    width: 100%;
    margin-top: 24px;
  }

  .modal-actions {
    padding-left: 18px;
    padding-right: 18px;
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    div:not(:last-child) {
      margin-right: 8px;
    }
  }

}

// INDIVIDUAL
// =====================
.Modal.create-api-token {
  .input-label {
    font-weight: 500;
  }

  .scopes {
    margin-top: 12px;
  }

  .checkbox-select {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .checkbox-label {
      font-size: 14px;
      font-weight: 500;
      margin-left: -2px;
    }
  }
}

.Modal.change-subscription-tier {
  .subscription-tier-name {
  }

  .subscription-tier-base-price {
    margin-top: 12px;
    font-weight: 500;
    font-size: 18px;
  }
}

.Modal.github-repositories {
  .repositories {
    margin-top: 12px;
    max-height: 500px;
    overflow-y: auto;
  }

  .repository {
    cursor: pointer;
    &:not(:first-child) { margin-top: 12px; }
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    &:hover { background-color: darken($crowdin-light-gray, 5%); }

    img.repository-image {
      width: 50px;
      height: 50px;
      margin-right: 10px;
    }

    .repository-title {
      display: flex;
      flex-direction: row;
      align-items: center;

      .repository-privacy-mode {
        margin-left: 10px;
        font-size: 13px;
      }
    }

    .repository-description {
      font-size: 14px;
    }
  }

  .selected-repository {
    margin-bottom: 12px;
    .repository {
      cursor: default;
      &:hover { background-color: transparent; }
    }
  }

}
