// SHARED
// =====================
$primary-hero-height: 360px;
$primary-header-height: 64px;
$signup-form-width: 472px;
$footer-margin-top: 72px;
$footer-height: 48px;

.Screen {
  min-height: calc(100vh - $primary-header-height - $footer-margin-top - $footer-height);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding-bottom: 80px;
}

// INDIVIDUAL
// =====================
.Screen.home-screen {
  display: flex;
  flex-direction: column;
  align-items: center;

  .hero.primary {
    padding-top: 72px;
    // padding-bottom: 72px;
    width: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;

    .text-content {
      width: 80%;
      display: flex;
      flex-direction: column;

      .hero-title {
        font-size: 48px;
        text-align: center;
        color: $ngrok-primary-text;
      }
      .hero-subtitle {
        font-size: 20px;
        margin-top: 12px;
        text-align: center;
        color: $ngrok-secondary-text;
      }
    }

    .action-buttons {
      width: 80%;
      margin-top: 48px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .call-to-action {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 14px;
        padding-bottom: 14px;
        p {
          font-size: 20px;
          font-weight: 500;
        }
      }

      .or {
        margin-left: 16px;
        margin-right: 16px;
      }

      .secondary-call-to-action {
        cursor: pointer;
        font-weight: 600;
        font-size: 20px;
        text-align: center;
      }

    }

  }

  .intro-video {
    margin-top: 48px;
  }

  .oauth-divider {
    width: 50%;
    margin-top: 72px;
    display: flex;
    flex-direction: row;
    .divider {
      position: relative;
      top: -8px;
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 1px;
        width: 100%;
      }
      &.fade-out:after {
        background: linear-gradient(to right, $charcoal-ultra-light 0%, $charcoal-ultra-light 90%, white 90%, white 100%);
      }
      &.fade-in:after {
        background: linear-gradient(to right, white 0%, white 10%, $charcoal-ultra-light 10%, $charcoal-ultra-light 100%);
      }
    }
  }

  .section-border {
    background-color: $crowdin-border-gray;
    height: 1px;
    width: 100%;
    margin-top: 72px;
    margin-bottom: 72px;
    max-width: 1000px;
  }

  .app-screenshot-container {
    padding-bottom: 72px;
    margin-top: 100px;
    display: flex;
    justify-content: center;
    .app-screenshot {
      width: 50%;
      box-shadow: 0px 42px 44px -10px rgba(1, 23, 48, 0.116);
    }
  }

  .getting-started-container {
    background-color: $blendin-secondary-gray;
    width: 100%;
    min-height: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 72px;
    padding-bottom: 72px;

    .getting-started-title {
      width: 80%;
      color: $ngrok-primary-text;
      font-size: 48px;
      font-weight: 700;
      text-align: center;
    }

    .getting-started-subtitle {
      width: 80%;
      color: $ngrok-secondary-text;
      margin-top: 12px;
      font-size: 20px;
      font-weight: 400;
      color: $ngrok-secondary-text;
      text-align: center;
    }

    .getting-started-language-select {
      margin-top: 48px;
      width: 80%;
      max-width: 650px;
      cursor: pointer;
      // background-color: rgb(69, 38, 80);
      background-color: $blendin-green;
      border-radius: 6px;
      color: $white;
      font-size: 16px;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 16px;
      padding-right: 16px;

      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      &::-ms-expand { display: none; }

      &:focus {
        outline: none;
      }
    }

    .language-specific-code {
      margin-top: 48px;
      width: 80%;
      max-width: 650px;

      .code-block-step {
        &:not(:first-child) {
          margin-top: 42px;
        }

        .code-block-step-meta {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 16px;

          .number-container {
            margin-right: 12px;
            width: 28px;
            height: 28px;
            background-color: fade-out($crowdin-green, 0.85);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            .number {
              font-size: 18px;
              color: $crowdin-green;
              margin: 0;
            }

          }

          .instructions {
            font-size: 16px;
          }

        }
      }

      pre {
        border-radius: 6px;
      }
    }

    .outro-text {
      margin-top: 42px;
      width: 80%;
      text-align: center;
      a {
        display: inline;
        color: $blendin-green;
      }
    }

    .action-buttons.secondary {
      margin-top: 42px;
      display: flex;
      flex-direction: row;
      .action-button.book-demo {
        margin-left: 18px;
      }
    }
  }

  .features-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @media screen and (min-width: 0px) and (max-width: 960px) {
      .feature {
        margin-top: 64px;
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .feature-text {
          .feature-title {
            font-size: 42px;
            font-weight: 700;
            color: $blendin-green;
          }
          .feature-description {
            margin-top: 16px;
            font-size: 22px;
            font-weight: 400;
            color: $ngrok-primary-text;
            max-width: 500px;
            line-height: 1.70rem;
          }
        }

        .feature-image {
          width: 100%;
          margin-top: 32px;
          &.white-glove-service { max-width: 400px; }
          &.powerful-integrations { max-width: 400px; }
          &.ai-native { max-width: 460px; }
        }
      }
    }

    @media screen and (min-width: 960px) {
      .feature {
        margin-top: 72px;
        width: 80%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        &.inverted {
          flex-direction: row-reverse;
        }

        .feature-text {
          .feature-title {
            font-size: 42px;
            font-weight: 700;
            color: $blendin-green;
          }
          .feature-description {
            margin-top: 16px;
            font-size: 22px;
            font-weight: 400;
            color: $ngrok-primary-text;
            max-width: 500px;
            line-height: 1.70rem;
          }
        }

        .feature-image {
          &.white-glove-service { width: 33.33%; }
          &.powerful-integrations { width: 33.33%; }
          &.ai-native { width: 40%; }
        }
      }
    }

  }

  .trusted-by-container {
    margin-top: 100px;
    width: 100%;
    background-color: $blendin-secondary-gray;
    height: 420px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .trusted-by-text {
      color: $ngrok-primary-text;
      margin-top: 42px;
      font-size: 36px;
      font-weight: 600;
    }

    .trusted-by-sales-copy {
      color: $ngrok-secondary-text;
      font-size: 18px;
      font-weight: 400;
      max-width: 680px;
      text-align: center;
      margin-top: 36px;
    }

    .trusted-by-logos {
      margin-top: 64px;
      max-width: 680px;
    }
  }

  .quickstart-title {
    color: $ngrok-primary-text;
    margin-top: 100px;
    font-size: 36px;
    font-weight: 600;
  }

  .quickstart-guide-container {
    // background-image: url('https://assets-global.website-files.com/61e07e4a00e7740ee9042cf3/61eb33edcf8b5cd108e17d94_cta-main-bg-blur-codely-webflow-ecommerce-template.png');
    // background-position: 0%;
    // background-repeat: no-repeat;
    // background-size: cover;
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 48px;
    margin-bottom: 48px;

    .quickstart-guide-accordion-container {
      width: 50%;
      margin-left: 10%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .quickstart-guide-accordion {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 400px;

        .quickstart-guide-accordion-segment {
          cursor: pointer;
          width: 100%;
          background-color: $white;
          box-shadow: 0 12px 28px 0 #0003,0 2px 4px 0 #0000001a;
          border-radius: 8px;
          &:not(:first-child) {
            margin-top: 12px;
          }

          .accordion-segment-title {
            height: 48px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .number-container {
              margin-left: 12px;
              margin-right: 12px;
              width: 36px;
              height: 36px;
              background-color: fade-out($crowdin-green, 0.85);
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;

              .number {
                font-size: 28px;
                color: $crowdin-green;
                margin: 0;
              }

            }

            .accordion-chevron {
              height: 32px;
              transition: transform 0.3s ease;

              &.rotated {
                transform: rotate(90deg);
              }
            }

          }

          .accordion-segment-content {
            &.collapsed { display: none; }
            // &.expanded {}

            p {
              padding: 12px;
            }
          }

        }
      }

    }

    .quickstart-guide-content-container {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      margin-right: 10%;
      // background-image: linear-gradient(135deg, rgba(73, 221, 216, 1) 0%, rgba(25, 226, 115, 1) 100%);
      border-radius: 12px;

      .quickstart-guide-content-selection-menu {
        display: flex;
        flex-direction: row;
        margin-bottom: 24px;

        .selection-item {
          cursor: pointer;
          &:not(:first-child) {
            margin-left: 24px;
          }
          &:hover {
            background-color: $white;
            color: $consulting-primary;
          }
          &.selected {
            text-decoration: underline;
            text-underline-offset: 6px;
            color: $consulting-primary;
          }
        }
      }

      img {
        width: 100%;
        // padding: 42px;
      }
    }
  }

  .featured-integrations-container {
    margin-top: 100px;
    width: 100%;
    height: 360px;
    border-top: 1px solid rgba(231, 233, 237, 1);

    .featured-integrations-title {
      color: $ngrok-primary-text;
      margin-top: 42px;
      font-size: 36px;
      font-weight: 600;
      margin-left: 10%;
    }
    .featured-integrations-logos {
      margin-top: 78px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;

      .featured-integrations-logo {
        width: 300px;
      }
    }
  }

  .pricing-container {
    background-color: rgba(244, 247, 250, 1);
    width: 100%;
    height: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .pricing-title {
      color: $ngrok-primary-text;
      margin-top: 42px;
      font-size: 36px;
      font-weight: 600;
    }

    .pricing-tiers {
      margin-top: 50px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      .pricing-tier {
        border: 1px solid rgba(231, 233, 237, 1);
        border-radius: 8px;
        background-color: $white;
        height: 600px;
        width: 350px;
        padding-left: 42px;
        &:not(:first-child) {
          margin-left: 32px;
        }

        .pricing-tier-title {
          margin-bottom: 42px;
          color: $blendin-green;
          font-size: 16px;
          margin-top: 36px;
        }

        .dollar-sign {
          font-size: 24px;
          font-weight: 700;
        }

        .amount-per-month {
          font-size: 60px;
          font-weight: 700;
        }

        .per-month {
          font-size: 18px;
          font-weight: 400;
        }

        .tier-features {
          margin-top: 74px;
          display: flex;
          flex-direction: column;

          .tier-feature {
            &:not(:first-child) {
              margin-top: 14px;
            }
            height: 30px;
            display: flex;
            flex-direction: row;
            align-items: center;

            img {
              margin-right: 12px;
            }

            &.disabled {
              .tier-feature-text {
                color: $ngrok-secondary-text;
              }
            }

          }
        }
      }
    }
  }

}

.Screen.signup-screen {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .signup-section {
    margin-top: 64px;

    &.signup-copy-section {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding-left: 64px;
      padding-right: 16px;
      h2 { text-align: center; }
      p {
        margin-top: 6px;
      }
    }

    &.signup-form-section {
      max-width: 640px;
      width: 80%;

      .email-signup {
        .action-button {
          margin-top: 28px;
        }
      }

      .oauth-divider {
        margin-top: 42px;
        margin-bottom: 42px;
        display: flex;
        flex-direction: row;
        .divider {
          position: relative;
          top: -8px;
          &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            height: 1px;
            width: 100%;
          }
          &.fade-out:after {
            background: linear-gradient(to right, $charcoal-ultra-light 0%, $charcoal-ultra-light 90%, white 90%, white 100%);
          }
          &.fade-in:after {
            background: linear-gradient(to right, white 0%, white 10%, $charcoal-ultra-light 10%, $charcoal-ultra-light 100%);
          }
        }
      }

      .oauth-signups {
        border-top: 2px solid $crowdin-border-gray;
        border-left: 2px solid $crowdin-border-gray;
        border-right: 2px solid $crowdin-border-gray;
        .oauth-signup {
          cursor: pointer;
          width: 100%;
          height: 44px;
          border-bottom: 2px solid $crowdin-border-gray;
          display: flex;
          flex-direction: column;
          justify-content: center;
          &:hover {
            background-color: $crowdin-border-gray;
          }
          .oauth-signup-option {
            margin-left: 18px;
            display: flex;
            flex-direction: row;
            align-items: center;
            img {
              height: 24px;
              width: 24px;
            }
            p {
              margin-left: 12px;
            }
          }
        }
      }

    }
  }
}

.Screen.login-screen {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .login-section {
    margin-top: 64px;

    &.login-copy-section {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding-left: 64px;
      padding-right: 16px;
      h2 { text-align: center; }
      p {
        margin-top: 6px;
      }
    }

    &.login-form-section {
      width: 80%;
      max-width: 640px;

      .email-login {
        .action-button {
          margin-top: 28px;
        }
      }

      .oauth-divider {
        margin-top: 42px;
        margin-bottom: 42px;
        display: flex;
        flex-direction: row;
        .divider {
          position: relative;
          top: -8px;
          &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            height: 1px;
            width: 100%;
          }
          &.fade-out:after {
            background: linear-gradient(to right, $charcoal-ultra-light 0%, $charcoal-ultra-light 90%, white 90%, white 100%);
          }
          &.fade-in:after {
            background: linear-gradient(to right, white 0%, white 10%, $charcoal-ultra-light 10%, $charcoal-ultra-light 100%);
          }
        }
      }

      .oauth-signups {
        border-top: 2px solid $crowdin-border-gray;
        border-left: 2px solid $crowdin-border-gray;
        border-right: 2px solid $crowdin-border-gray;
        .oauth-signup {
          cursor: pointer;
          width: 100%;
          height: 44px;
          border-bottom: 2px solid $crowdin-border-gray;
          display: flex;
          flex-direction: column;
          justify-content: center;
          &:hover {
            background-color: $crowdin-border-gray;
          }
          .oauth-signup-option {
            margin-left: 18px;
            display: flex;
            flex-direction: row;
            align-items: center;
            img {
              height: 24px;
              width: 24px;
            }
            p {
              margin-left: 12px;
            }
          }
        }
      }

    }
  }
}

.Screen.user-dashboard-screen {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .null-state-text {
    margin-top: 42px;
  }

  .user-email {
    margin-top: 42px;
  }

  .projects-table {
    margin-top: 42px;
  }

  .action-buttons {
    margin-top: 42px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .create-project {
    width: 172px;
    height: 42px;
  }
}

.Screen.project-create-screen {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .page-title {
    font-size: 32px;
    margin-top: 42px;
  }

  .form-section {
    margin-top: 32px;
    margin-bottom: 80px;
  }

  .privacy-mode {
    margin-top: 32px;
    display: flex;
    flex-direction: column;

    .privacy-mode-option {
      &:not(:first-child) {
        margin-top: 10px;
      }
    }
  }

  .source-locale {
    margin-top: 32px;
  }

  .source-locale-select {
    cursor: pointer;
  }

  .target-locales {
    margin-top: 32px;
  }

  .target-locales-select {
    height: 480px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    border-radius: 8px;
    border: 1px solid $crowdin-border-gray;
    margin-bottom: 48px;

    .select-locales {
      width: 50%;
      max-height: 100%;
      overflow-y: scroll;
      .target-locale-select {
        &:hover { background-color: $crowdin-ultralight-gray-005; }
        cursor: pointer;
        display: flex;
        flex-direction: row;
        height: 32px;
        align-items: center;
        .checkbox-wrapper {
          top: 0px;
          margin-left: 8px;
          margin-right: 0px;
        }
        .locale-flag {
          width: 14px;
          margin-left: 10px;
        }
        p {
          margin-left: 8px;
        }
      }
    }

    .selected-locales {
      width: 50%;
      background-color: $crowdin-light-gray;

      .selected-locale {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .locale-flag {
          width: 14px;
          margin-left: 10px;
        }
        .selected-locale-text {
          margin-left: 10px;
        }

      }
    }

  }

  .action-buttons {
    display: flex;
    flex-direction: row;
  }
}

.Screen.project-dashboard-screen {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .project-name-container {
    margin-top: 42px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .project-privacy-mode {
      margin-left: 12px;
    }
  }

  .project-content {
    display: flex;
    flex-direction: row;
  }

  .project-locales {
    margin-top: 24px;
    width: 70%;

    .project-locale {
      height: 56px;
      cursor: pointer;
      &:hover { background-color: $crowdin-gray-002; }
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      .locale-iso-wrapper {}
      .locale-flag {
        width: 18px;
      }
      .locale-iso {
        display: inline-block;
        background-color: $crowdin-light-gray;
        font-size: 14px;
        font-weight: 500;
        padding: 5px;
      }
      .locale-name {
        margin-left: 16px;
      }
      &:not(:last-child) {
        border-bottom: 1px solid $crowdin-border-gray;
      }
      .translation-progress-bar-container {
        margin-right: 24px;
        width: 300px;
        height: 12px;
        background-color: $crowdin-ultralight-gray;
        position: relative;

        .translation-progress-bar-translation-percentage {
          position: absolute;
          height: 100%;
          background-color: $crowdin-light-blue;
        }

        .translation-progress-bar-confirmation-percentage {
          position: absolute;
          height: 100%;
          background-color: $crowdin-light-green;
          z-index: 2;
        }
      }
      .translation-progress-text-container {
        width: 108px;
        margin-right: 12px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        background-color: $crowdin-light-gray;
        border-radius: 4px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 6px;
        padding-bottom: 6px;
        .translation-progress-text {
          font-size: 14px;
          width: calc(100% / 3);
          text-align: center;
          &:nth-child(2) {
            margin-left: 4px;
          }
        }
      }
    }

  }

  .project-summary {
    width: 25%;
    background-color: $crowdin-light-gray;
    border-radius: 16px;
    padding: 24px;

    .project-summary-title {
      font-weight: 600;
      margin-bottom: 20px;
    }

    .project-detail-row {
      margin-top: 4px;
      &.section-spacing { margin-top: 24px; }
      p {
        font-size: 14px;
        &.project-detail-value {
          font-weight: 500;
        }
      }
      display: flex;
      flex-direction: row;
    }
  }

  .header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 42px;
  }

  .files-table, .strings-table, .connections-table, .translation-jobs-table, .activities-table {
    margin-top: 18px;
  }

  .strings-title, .activities-title {
    margin-top: 42px;
  }

  .config-files-container {
    margin-top: 42px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .config-title-container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .copy-icon {
      cursor: pointer;
      margin-left: 8px;
      height: 16px;
      transition: transform 0.1s;
      -webkit-tap-highlight-color: transparent;
      &:active {
        transform: scale(0.9);
      }
    }

    .config-file {
      width: 50%;
    }
  }

}

.Screen.demo-create-screen {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .form-content {
    margin-top: 24px;
  }

  .all-locales {
    margin-top: 24px;
    .target-locales-select {
      margin-top: 10px;
    }
  }
}

.Screen.pricing-screen {

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  @media screen and (min-width: 0px) and (max-width: 480px) {
    .Section {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .pricing-header {
      flex-direction: column;
      align-items: center;
      .Toggle-Switch {
        margin-top: 12px;
      }
    }

    .subscription-tiers {
      flex-direction: column;

      .subscription-tier:not(:first-child) {
        margin-top: 24px;
      }
    }

  }

  @media screen and (min-width: 480px) {
    .Section {
      padding-left: 64px;
      padding-right: 64px;
    }

    .pricing-header {
      flex-direction: row;
    }

    .subscription-tiers {
      flex-direction: row;
      justify-content: space-between;
      padding-left: 10%;
      padding-right: 10%;
    }
  }

  .pricing-section {
    width: 100%;
    background-color: $blendin-green;
    padding-bottom: 72px;
  }

  .pricing-header {
    margin-top: 32px;
    display: flex;
    .pricing-title {
      font-size: 42px;
      color: $white;
      font-weight: 700;
    }
  }

  .subscription-tiers {
    margin-top: 32px;
    display: flex;

    .subscription-tier {
      min-width: 320px;
      display: flex;
      flex-direction: column;
      background-color: $white;
      width: 23%;
      border: 1px solid $crowdin-border-gray;
      border-radius: 8px;
      padding-left: 18px;
      padding-right: 18px;
      padding-top: 18px;
      padding-bottom: 64px;

      .subscription-tier-name {
        color: $blendin-green;
        font-size: 30px;
        font-weight: 700;
      }

      .subscription-tier-base-price {
        font-weight: 500;
        font-size: 18px;

        .amount {
          font-size: 60px;
          font-weight: 700;
        }
      }

      .usage-pricing {
        margin-top: 12px;
        height: 32px;
        display: flex;
        align-items: center;
      }

      .usage-pricing-select {
        cursor: pointer;
        margin-top: 12px;
        height: 32px;
      }

      .select-plan {
        margin-top: 12px;
      }

      hr {
        margin-top: 24px;
        margin-bottom: 24px;
      }

      .subscription-tier-features {
        display: flex;
        flex-direction: column;

        .tier-feature {
          &:not(:first-child) {
            margin-top: 8px;
          }
          height: 30px;
          display: flex;
          flex-direction: row;
          align-items: center;

          img {
            margin-right: 12px;
          }

          &.disabled {
            .tier-feature-text {
              color: $ngrok-secondary-text;
            }
          }

        }
      }

      .select-plan {
        margin-top: 64px;
        height: 48px;
        border-radius: 8px;

        &.active-subscription-tier {
          cursor: not-allowed;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 48px;
          border-radius: 8px;
          background-color: fade-out($blendin-green, 0.85);
          color: $blendin-green;

          .active-subscription-tier-text {
            font-weight: 700;
            font-size: 17px;
          }
        }

        .action-button-text {
          font-weight: 700;
          font-size: 17px;
        }

      }

      .no-credit-card-required {
        margin-top: 12px;
        color: $ngrok-secondary-text;
      }

    }
  }

}

.Screen.closed-beta-screen {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 0px) and (max-width: 480px) {
    .title-text {
      font-size: 72px;
    }
    .subtitle-text {
      font-size: 28px;
      max-width: 96%;
    }

  }

  @media screen and (min-width: 480px) {
    .title-text {
      font-size: 92px;
    }

    .subtitle-text {
      font-size: 30px;
      max-width: 80%;
    }
  }

  .wait-in-line-container {
    margin-top: 64px;
    max-width: 860px;
    .wait-in-line-image {
      width: 100%;
    }
  }

  .title-text {
    margin-top: 24px;
    font-weight: 700;
    text-align: center;
  }

  .subtitle-text {
    margin-top: 24px;
    font-weight: 400;
    text-align: center;
  }

  .action-button.primary {
    margin-top: 48px;
    height: 64px;

    .action-button-text {
      font-size: 20px;
    }
  }

  .read-developer-docs {
    margin-top: 48px;
    font-size: 20px;
    font-weight: 400;
    color: $blendin-green;
  }

}

// .project-translation-job-create-screen {
//   display: flex;
//   justify-content: center;

//   .page-title {
//     font-size: 32px;
//     margin-top: 42px;
//   }

//   .form-section {
//     margin-top: 32px;
//     margin-bottom: 80px;
//   }

//   .privacy-mode {
//     margin-top: 32px;
//     display: flex;
//     flex-direction: column;

//     .privacy-mode-option {
//       &:not(:first-child) {
//         margin-top: 10px;
//       }
//     }
//   }

//   .source-locale {
//     margin-top: 32px;
//   }

//   .source-locale-select {
//     cursor: pointer;
//   }

//   .target-locales {
//     margin-top: 32px;
//   }

//   .target-locales-select {
//     height: 480px;
//     display: flex;
//     flex-direction: row;
//     justify-content: flex-start;
//     align-items: stretch;
//     border-radius: 8px;
//     border: 1px solid $crowdin-border-gray;
//     margin-bottom: 48px;

//     .select-locales {
//       width: 50%;
//       max-height: 100%;
//       overflow-y: scroll;
//       .target-locale-select {
//         &:hover { background-color: $crowdin-ultralight-gray-005; }
//         cursor: pointer;
//         display: flex;
//         flex-direction: row;
//         height: 32px;
//         align-items: center;
//         .checkbox-wrapper {
//           top: 0px;
//           margin-left: 8px;
//           margin-right: 0px;
//         }
//         .locale-flag {
//           width: 14px;
//           margin-left: 10px;
//         }
//         p {
//           margin-left: 8px;
//         }
//       }
//     }

//     .selected-locales {
//       width: 50%;
//       background-color: $crowdin-light-gray;
//     }

//   }

//   .action-buttons {
//     display: flex;
//     flex-direction: row;
//   }
// }
